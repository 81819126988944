<template>
  <default-template>
    <v-layout justify-center align-start>
      <v-responsive width="100wv">
        <div class="sub-visual-container sv1">
          <div class="sub-visual-wrapper">
            <h1 class="sub-visual--title">{{ siteInfo.serviceNameKo || siteInfo.projectNameKo }} 소개</h1>
            <p>
              개인/기업의 이벤트, 마케팅, 고객 관리의 목적으로 모바일 쿠폰을
              언제 어디서나 쉽게 실시간으로 발송할 수 있는 서비스입니다.
            </p>
          </div>
        </div>
        <div
          class="sub-container"
          style="max-width: 1230px; width: 100%; margin: 0 auto"
        >
          <div class="sub-section">
            <div class="sub-section--intro">
              <p class="sub-section--title">한국상품권협회 공식 인증 쇼핑몰</p>
              <p class="intro-title">
                안녕하세요 모바일쿠폰 전문 쇼핑몰
                <span>{{ siteInfo.serviceNameKo || siteInfo.projectNameKo }}</span> 입니다.
              </p>
              <p>
                {{ siteInfo.serviceNameKo || siteInfo.projectNameKo }}는 한국상품권협회 공식 인증
                쇼핑몰로써 사업에 필요한 모바일쿠폰을 안정적으로 판매
                공급하는데에 최선을 다하도록 하겠습니다.
              </p>
              <div class="plusu-work">
                <ul>
                  <li>
                    <div class="work-thumb">
                      <div
                        class="work-thumb--img"
                        style="
                          background-image: url('images/subcontent/work1img.png');
                        "
                      ></div>
                      <p class="work-thumb--tit">한국상품권협회</p>
                      <p class="work-thumb--txt">
                        상품권유통업을 10여년 해온 경험과 노하우를 접목한 협회
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="work-thumb">
                      <div
                        class="work-thumb--img"
                        style="
                          background-image: url('images/subcontent/work2img.png');
                        "
                      ></div>
                      <p class="work-thumb--tit">모바일 쿠폰</p>
                      <p class="work-thumb--txt">
                        모바일 쿠폰, 이벤트 당첨 선물 사은품 등으로 활용
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="work-thumb">
                      <div
                        class="work-thumb--img"
                        style="
                          background-image: url('images/subcontent/work3img.png');
                        "
                      ></div>
                      <p class="work-thumb--tit">안정적인 판매 공급</p>
                      <p class="work-thumb--txt">
                        안정적으로 판매 공급하는데에 최선을 다하고 있습니다
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="sub-section--items sub-section--business">
              <p class="sub-section--subtitle">비지니스 제휴/제안</p>
              <div class="business-work">
                <div class="business-work--box">
                  <p class="business-box--tit">절차</p>
                  <img src="images/subcontent/order-img.png" alt="" />
                </div>
                <div class="business-work--box">
                  <p class="business-box--tit">문의</p>
                  <p class="business-box--txt">
                    전화 문의 전 이메일로 제휴에 대한 상세한 내용을 먼저
                    보내주시기 바랍니다. <br class="pc" />
                    * 서비스 제휴와 관련된 내용이 아닌 경우 답변이 어려울 수
                    있습니다.
                  </p>
                  <ul>
                    <li>
                      <img src="images/nextmore/business-mail.png" alt="" />
                    </li>
                    <li>
                      <img src="images/nextmore/business-tel.png" alt="" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="sub-section--items sub-section--shop">
              <p class="sub-section--subtitle">모바일쿠폰 전문 쇼핑몰</p>
              <div class="shop-advantages">
                <ul>
                  <li>
                    <div class="shop-thumb">
                      <div
                        class="shop-thumb--img"
                        style="
                          background-image: url('images/subcontent/mobile-shop1.png');
                        "
                      ></div>
                      <p class="shop-thumb--tit">
                        {{ siteInfo.serviceNameKo || siteInfo.projectNameKo }}의 장점
                      </p>
                      <p class="shop-thumb--subtit">언제나 열려있는 쇼핑몰</p>
                      <p class="shop-thumb--txt">
                        365일 24시간 열려있는 쇼핑몰로써 회원가입 없이 간편하게
                        소액결제로 이용하여 구매가 가능합니다.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="shop-thumb">
                      <div
                        class="shop-thumb--img"
                        style="
                          background-image: url('images/subcontent/mobile-shop2.png');
                        "
                      ></div>
                      <p class="shop-thumb--tit">
                        {{ siteInfo.serviceNameKo || siteInfo.projectNameKo }}의 발송 서비스
                      </p>
                      <p class="shop-thumb--subtit">
                        보안된 실시간 모바일 핀번호
                      </p>
                      <p class="shop-thumb--txt">
                        한층 보완된 실시간 모바일 핀번호 발송 서비스 기다리는
                        시간없이 구매 후 사용이 가능하도록 자동 발송
                        해드리겠습니다.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </v-responsive>
    </v-layout>
  </default-template>
</template>
<script>
import { mapState } from "vuex";
import DefaultTemplate from "../DefaultTemplate.vue";
export default {
  components: {
    DefaultTemplate,
  },
  computed: {
    ...mapState(["siteInfo"]),
  },
};
</script>

<style scoped>
/*20220511 sub css*/
/*RESECT*/
img {
  max-width: 100%;
}
ul,
ol {
  list-style: none;
  padding: 0px !important;
}
/* COMMON */
.sub-visual-container {
  overflow: hidden;
  padding: 60px 0;
  height: 350px;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-animation: visualFadeIn 0.4s ease-in-out 0.1s forwards;
  animation: visualFadeIn 0.4s ease-in-out 0.1s forwards;
  opacity: 0;
}
.sub-visual-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  padding: 0px 20px;
}
.sub-visual-container .sub-visual--title {
  z-index: 2;
  position: relative;
  line-height: 1;
  text-align: center;
  font-size: 42px;
  font-weight: 700;
  color: #ffffff;
  transition: all 0.3s ease;
}
.sub-visual-container .sub-visual--title small {
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
  color: #bcc5df;
  font-weight: 300;
}
.sub-visual-container p {
  font-size: 16px;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 15px;
  transition: all 0.3s ease;
  margin-bottom: 0px;
  text-align: center;
}

.contents {
  padding: 120px 0px;
}

.tit {
  font-size: 4rem;
  font-weight: 400;
  line-height: 1.4;
}

.tit strong {
  font-weight: 900;
}

.sv1 {
  background-image: url("/images/subvisual/sv1.png");
}
.sv2 {
  background-image: url("/images/subvisual/sv2.png");
}
.sv3 {
  background-image: url("/images/subvisual/sv3.png");
}
.sv4 {
  background-image: url("/images/subvisual/sv4.png");
}
.sv5 {
  background-image: url("/images/subvisual/sv5.png");
}
@-webkit-keyframes visualFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes visualFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 1200px) {
  .sub-visual-container {
    padding: 50px 0px;
  }
  /* .sub-visual-wrapper {margin-top:50px;} */
}
@media (max-width: 1024px) {
  .sub-visual-container {
    padding: 0;
    height: 240px;
    background-size: cover;
  }
  .sub-visual-container .sub-visual--title {
    font-size: 34px;
  }
  .sub-visual-container .sub-visual--title small {
    font-size: 13px;
  }

  /* .sub-visual-wrapper {margin-top:60px;} */
}
@media (max-width: 768px) {
  /* .sub-visual-wrapper {margin-top:55px;} */
  .sub-visual-container {
    padding: 0;
    height: 160px;
  }
  .sub-visual-container .sub-visual--title {
    font-size: 24px;
  }
  .sub-visual-container .sub-visual--title small {
    margin-bottom: 10px;
    font-size: 11px;
  }
  .sub-visual-container p {
    margin-top: 10px;
  }
  .contents {
    padding: 80px 0px;
  }
}

/*Sub Container Common*/
.sub-container {
  padding: 50px 15px 80px;
}

.sub-container p {
  font-size: 14px;
  color: #595959;
  margin-bottom: 0px;
  letter-spacing: -0.02em;
}

.sub-container p.sub-section--title {
  font-weight: 700;
  font-size: 30px;
  color: #111111;
  padding-bottom: 13px;
  margin-bottom: 13px;
  position: relative;
}

.sub-container p.sub-section--title::after {
  content: "";
  display: block;
  width: 25px;
  height: 2px;
  background-color: #ff3c5b;
  position: absolute;
  bottom: 0;
  left: 0;
}

.sub-container .sub-section--items {
  padding-top: 45px;
}

.sub-container p.sub-section--subtitle {
  font-weight: 700;
  color: #111111;
  font-size: 24px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .sub-container {
    padding: 70px 15px 110px;
  }

  .sub-section--items {
    padding-top: 60px;
  }
}

@media (min-width: 1024px) {
  .sub-container {
    padding: 140px 15px 220px;
  }

  .sub-container p {
    font-size: 16px;
  }

  .sub-container p.sub-section--title {
    font-size: 42px;
    padding-bottom: 26px;
    margin-bottom: 26px;
  }

  .sub-container .sub-section--items {
    padding-top: 120px;
  }

  .sub-container p.sub-section--subtitle {
    font-size: 32px;
    margin-bottom: 30px;
  }
}

/*Sub*/
.sub-container p.intro-title {
  font-size: 18px;
  font-weight: 500;
  color: #111111;
  margin-bottom: 5px;
}

.sub-container p.intro-title span {
  color: #ff3c5b;
}

.plusu-work {
  margin-top: 20px;
}

.plusu-work ul {
  display: flex;
  flex-wrap: wrap;
  margin: -15px -7px;
}

.plusu-work li {
  flex: 1 1 100%;
  max-width: 100%;
  padding: 15px 7px;
}

.plusu-work .work-thumb--img {
  position: relative;
  width: 100%;
  height: 0;
  border-radius: 15px;
  overflow: hidden;
  padding-top: calc(230 / 380 * 100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.plusu-work .work-thumb--tit {
  padding-top: 15px;
  font-size: 20px;
  color: #111111;
  font-weight: 500;
}

.plusu-work .work-thumb--txt {
  padding-top: 5px;
}

.business-work {
  border-radius: 15px;
  overflow: hidden;
  padding: 20px;
  background-color: #fafafa;
}

.business-work--box:last-child {
  margin-top: 30px;
}

.business-work--box .business-box--tit {
  font-size: 22px;
  font-weight: 700;
  color: #111;
  margin-bottom: 10px;
}

.business-work--box .business-box--txt {
  margin-bottom: 15px;
}

.business-work--box ul {
  display: flex;
  flex-wrap: wrap;
  margin: -5px -20px;
}

.business-work--box li {
  padding: 5px 20px;
  flex: 1 1 100%;
  max-width: 100%;
}

.business-work--box img {
  width: 100%;
}

.shop-advantages ul {
  display: flex;
  flex-wrap: wrap;
  margin: -15px -20px;
}

.shop-advantages li {
  flex: 1 1 100%;
  max-width: 100%;
  padding: 15px 20px;
}

.shop-advantages .shop-thumb--img {
  position: relative;
  width: 100%;
  height: 0;
  border-radius: 15px;
  overflow: hidden;
  padding-top: calc(300 / 585 * 100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.shop-advantages .shop-thumb--tit {
  padding-top: 15px;
  font-size: 20px;
  color: #111111;
  font-weight: 500;
}

.shop-advantages .shop-thumb--subtit {
  color: #ff3c5b;
  padding-top: 5px;
}

.shop-advantages .shop-thumb--txt {
  padding-top: 5px;
}

@media (min-width: 640px) {
  .plusu-work ul {
    margin: -6px -10px;
  }
  .plusu-work li {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    padding: 6px 10px;
  }

  .business-work--box ul {
    margin: -10px -20px;
  }

  .business-work--box li {
    padding: 10px 20px;
    flex: 1 1 50%;
    max-width: 50%;
  }

  .shop-advantages ul {
    margin: -10px -20px;
  }

  .shop-advantages li {
    flex: 1 1 50%;
    max-width: 50%;
    padding: 10px 20px;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 1024px) {
  .sub-container p.intro-title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .plusu-work {
    margin-top: 40px;
  }

  .plusu-work ul {
    margin: -10px -15px;
  }
  .plusu-work li {
    padding: 10px 15px;
  }

  .plusu-work .work-thumb--tit {
    padding-top: 30px;
    font-size: 22px;
  }

  .plusu-work .work-thumb--txt {
    padding-top: 10px;
  }

  .business-work {
    padding: 50px 60px;
  }

  .business-work--box:last-child {
    margin-top: 60px;
  }

  .business-work--box .business-box--tit {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .business-work--box .business-box--txt {
    margin-bottom: 30px;
  }

  .shop-advantages .shop-thumb--tit {
    padding-top: 30px;
    font-size: 22px;
  }

  .shop-advantages .shop-thumb--subtit {
    padding-top: 10px;
  }

  .shop-advantages .shop-thumb--txt {
    padding-top: 10px;
  }
}
</style>
